'use strict';

window.$ = window.jQuery = require('jquery');
require('bootstrap');

// c.f. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Using_special_characters
function escapeRegExp(string){
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

if (window.chrome !== undefined) {
  let elements = document.getElementsByClassName('unhide-in-chrome');
  let i;
  for (i = 0; i < elements.length; i++) {
    elements[i].classList.remove('hidden');
  }
  elements = document.getElementsByClassName('hide-in-chrome');
  for (i = 0; i < elements.length; i++) {
    elements[i].classList.add('hidden');
  }
}

const bookmarkletInstaller = document.getElementById('js-bookmarklet-install');
if (bookmarkletInstaller) {
  bookmarkletInstaller.addEventListener('click', (event) => {
    window.alert('Drag me to the bookmarks bar');
    event.preventDefault();
  });
}

const chromeextInstaller = document.getElementById('js-chrome-extension-install');
if (chromeextInstaller) {
  chromeextInstaller.addEventListener('click', (event) => {
    window.chrome.webstore.install();
    event.preventDefault();
  });
}

const viaForm = document.querySelector('.js-via-form');
if (viaForm) {
  viaForm.addEventListener('submit', (event) => {
    const url = event.target.elements.url.value;
    if (url !== '') {
      const via_base_url = window.via_base_url || 'http://via.projet-episteme.org/';
      window.location.href = via_base_url + url;
    }
    event.preventDefault();
  });
}

const loginContainer = document.querySelector('nav>ul.login');
if (loginContainer) {
  fetch(window.base_url + "login", {credentials: 'same-origin'}).then(function(response) {
    const redirectRegexp = new RegExp(escapeRegExp(window.base_url) + "users\/(.+)");
    const redirectRes = response.redirected && redirectRegexp.exec(response.url);
    if(redirectRes) {
      loginContainer.insertAdjacentHTML('beforeend','\
      <li><a href="'+ response.url +'">'+ redirectRes[1] +'</a></li>\
      <li><a href="'+ window.base_url +'logout">Log out</a></li>\
      ');
    } else {
      loginContainer.insertAdjacentHTML('beforeend','\
      <li><a href="'+ window.base_url +'login">Log in</a></li>\
      <li><a href="'+ window.base_url +'signup">Create an account</a></li>\
    ');
    }
  }).catch(function(error) {
    loginContainer.insertAdjacentHTML('beforeend','\
      <li><a href="'+ window.base_url +'login">Log in</a></li>\
      <li><a href="'+ window.base_url +'signup">Create an account</a></li>\
  ' );
  })
}
